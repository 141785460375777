import { GetStartedLink, ValueProps } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { stepExistingOfferIdeas as ideas } from '../../../data/content/how-it-works';
import { metadata } from '../../../data/metadata/images';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import { CallToActionButton } from '../../button';
import { LinkWrapper, UnderlinedLinkInternal } from '../../links';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../../typography';

const SectionStyle = createOwnUpStyle({
  margin: 30,
  variants: {
    mediumAndDown: {
      margin: '64px 30px'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

const TextWrapperStyle = createOwnUpStyle({
  textAlign: 'center'
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

export const imageStyle = {
  borderRadius: '50%',
  margin: 'auto',
  maxWidth: 250
};

const ValuePropsWrapperStyle = createOwnUpStyle({
  '& > section > div > div': {
    gridTemplateColumns: '390px 390px',
    columnGap: '30px'
  },
  variants: {
    medium: {
      '& > section > div > div': {
        gridAutoFlow: 'column',
        gridTemplateColumns: '340px 340px',
        columnGap: '30px'
      }
    },
    small: {
      '& > section > div > div': {
        gridTemplateColumns: '325px'
      }
    },
    xsmall: {
      '& > section > div > div': {
        gridTemplateColumns: '200px',
        margin: 0
      }
    }
  }
});

const ValuePropsWrapper = createOwnUpComponent('div', ValuePropsWrapperStyle);

export const StepExistingOffer = () => {
  const mixpanel = useMixpanel();

  return (
    <Section aria-label="existing offer">
      <TextWrapper>
        <ResponsiveSectionHeader>
          Already have a loan offer?
          <br />
          We can help.
        </ResponsiveSectionHeader>
        <ValuePropsWrapper>
          <ValueProps valueProps={ideas} metadata={metadata} />
        </ValuePropsWrapper>
        <LinkWrapper>
          <GetStartedLink
            discover={`${process.env.GATSBY_DISCOVER}/get-started`}
            mixpanelEvent={{
              hook: mixpanel,
              name: 'Click discover CTA',
              properties: { 'page-location': 'how-it-works-loan-offer' }
            }}
          >
            <CallToActionButton>LET&apos;S TALK</CallToActionButton>
          </GetStartedLink>
        </LinkWrapper>
        <div
          style={{
            margin: 30
          }}
        >
          <ResponsivePrimaryText>
            Need More Knowledge?{' '}
            <UnderlinedLinkInternal to="/faq/">Check out our FAQS.</UnderlinedLinkInternal>
          </ResponsivePrimaryText>
        </div>
      </TextWrapper>
    </Section>
  );
};
